import swal from 'sweetalert2';
export default class Utils{
    swalLoading(title, text, html){
        swal.fire({
            title: title || 'Aguarde',
            text: text || 'Processo em Andamento',
            html: html || null,
            showConfirmButton: false,
            allowOutsideClick: false,
            onBeforeOpen: () => {
                swal.showLoading()
            },
        })
    }
    info(text){
        swal.fire({
            icon: 'info',
            text: text,
        })
    }
    error(text){
        swal.fire({
            icon: 'error',
            text: text,
        })
    }
    success(text){
        swal.fire({
            icon: 'success',
            text: text,
        })
    }
    close(){
        swal.close();
    }
}

